import React, { useState } from 'react';
import backgroundImage from './images/home.jpg'; // Make sure the path is correct
import Notification from './Notification';
import Form from './Form';  // Import the reusable form component
import './stylesheets/style.css'
import videoSource from './images/background.mp4'; // Adjust the path as necessary
function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        serviceType: '',
        message: '',
        consent: false
    });
    const [notification, setNotification] = useState({ message: '', type: 'info', isVisible: false });

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
        console.log(name, type, checked, value); // Esto te ayudará a ver qué recibe la función
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Form Data:', formData); // Check what data is being sent
        try {
            const response = await fetch('https://back-end-2fin.onrender.com/Back-End', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setNotification({ message: 'Email sent successfully!', type: 'success', isVisible: true });
            } else {
                const errorResponse = await response.text(); // If not OK, get the response text
                throw new Error(`Submission failed: ${errorResponse}`);
            }
        } catch (error) {
            console.log('Error:', error);
            setNotification({ message: 'Error sending email!', type: 'error', isVisible: true });
        }
    };

    return (
        <section id="introduce" className="ftco-section ftco-no-pt ftco-no-pb" >
            <div className="video-background">
                <video autoPlay loop muted playsInline id="video1">
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="container">
                <div id="introduce-container" className="row d-flex no-gutters">
                    <Form formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    <div className="col-md-6 pl-md-5 pt-md-5" data-aos="fade-up">
                        <div className="row justify-content-start py-5">
                            <div className="col-md-12 heading-section ftco-animate">
                                <span className="subheading">Welcome to McDream Cleaning Company</span>
                                <h2 className="mb-4">Professional Home Cleaning Services in Milwaukie, Portland</h2>
                                <p id="introduce-p">Experience a new level of freshness and cleanliness with our expert cleaning services. Our dedicated team provides top-notch cleaning services for your home in Milwaukie, Portland. Whether it's a one-time deep clean or regular maintenance, we ensure your home is spotless and welcoming. Contact us today to schedule your cleaning and experience the McDream Cleaning difference in Milwaukie, Portland!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {notification.isVisible && (
                <Notification message={notification.message} type={notification.type} />
            )}
        </section>
    );
}

export default Contact;
